@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}


:root{
    /* colors  */
    --bg : #f5b2eb;
    --primary : #ff00db;
    --primary-dark: #b5009b;
    --primary-light: #f79cea;
    --secondary: #ffe000;
    --secondary-dark: #b6a000;
    --secondary-light: #b6a000;
    --text : #202020;

    /* transitions  */
    --trsn1 : all 0.2s ease-in-out;
    --trsn2 : all 0.4s ease-in-out;
    --trsn3 : all 0.6s ease-in-out;

}

body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--bg);
}

/* re-useable css */

.btn{
    font-size: 16px;
    color: #fff;
    border: none;
    background: var(--primary);
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
}

.btn:hover{
    background: var(--primary-dark);
    transition: var(--trsn2);
}

.btn2{
    font-size: 18px;
    color: #fff;
    border: none;
    background: var(--primary);
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer; 
}

.btn2:hover{
    background: var(--primary-dark);
    transition: var(--trsn2);
}

.hamburger_icon{
    display: none;
    font-size: 20px;
    color: var(--text);
    cursor: pointer;
}

.main-hr{
    text-align: center;
    border: 1px solid var(--primary);
    width: 90%;
    height: 2px;
    color: var(--primary);
    margin: 50px  auto;
}

.secondary-hr{
    width: 90%;
    height: 2px;
    color: var(--primary);
    margin: 10px  auto;
    border: 1px solid var(--primary);
}

/* Section Heading text styling */
section .heading-text{
    display: flex;
    align-items: center;
    justify-content: center;
}

section .heading-text h2{
    position: relative;
    font-size: 39px;
    color: var(--primary);
    margin-bottom: 20px;
}

section .heading-text h2::before{
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 3px;
    background: var(--primary-dark);
}

section .heading-text h2::after{
    width: max-content;
    position: absolute;
    left: 50%;
    bottom: -19px;
    font-size: 17px;
    font-weight: 100;
    color: var(--text);
    background: var(--bg);
    transform: translateX(-50%);
    padding: 0 9px;

}

/* Navbar styling  */

.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    width: 100vw;
    height: 60px;
}

.logo{
    display: block;
    padding: 0 20px;
}

.logo h3{
    font-size: 28px;
}

.logo span{
    color: var(--primary);
}

.logo_icon{
    color: red;
    font-size: 14px;
}

.logo p{
    color: var(--text);
    font-size: 11px;
    transform: translateY(-5px);
}

.navlist {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 20px;
}

.navlist .nav-links{
    display: flex;
    align-items: center;
    gap: 20px;
    list-style: none;
    font-size: 17px;
    cursor: pointer;
}

.navlist .nav-links .link{
    text-decoration: none;
    color: var(--text);
}

.navlist .nav-links>.link:hover{
    transition: var(--trsn2);
    color: var(--primary);
}

/* Home styling */

.container{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.left{
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-left: 120px;
}

.left h1{
    font-size: 40px;
    color: var(--primary);
    padding-right: 50px;
}

.left p{
    padding-right: 150px;
    font-size: 18px;
    color: var(--text);
}

.Right{
    width: 50%;
}

.Right img{
    width: 90%;
}

/* Services section styling */
.services .heading-text h2::after{
    content: 'Check out!';
}

.service-boxes{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin: 40px 0;
}

.service-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 230px;
    background: linear-gradient(rgba(255, 0, 219, 0.1), rgba(255, 0, 219, 0.1));
    box-shadow: 0 3px 8px rgba(0, 0, 0 , 0.5);
    border-radius: 6px;
    transition: var(--trsn2);
    cursor: pointer;
}

.service-box:hover{
    transform: translateY(-10px);
}

.service-icon{
    font-size: 45px;
    color: var(--primary-dark);
    margin-top: 10px;
    margin-bottom: 10px;
}

.service-heading{
    color: var(--primary);
}

.service-para{
    align-items: center;
    color: var(--text);
    font-size: 15px;
    padding: 5px 20px;
}

/* Design section styling */
.designs .heading-text h2::after{
    content: 'Look out!';
}

.design-boxes{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 40px 0;
}

.design-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 190px;
    background: linear-gradient(rgba(255, 0, 219, 0.1), rgba(255, 0, 219, 0.1));
    box-shadow: 0 3px 8px rgba(0, 0, 0 , 0.5);
    border-radius: 6px;
    transition: var(--trsn2);
    cursor: pointer;
}

.design-box:hover{
    transform: translateY(-10px);
}

.design-box img{
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}


/* Contact section styling */
.contact .heading-text h2::after{
    content: 'Get in Touch!';
}

.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 50px;
}

.contact-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;
}

.left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    height: 100%;
    gap: 5%;
}

.left h2{
   font-size: 35px;
   color: var(--primary);
}

.left p{
    font-size: 16px;
    color: var(--text);
}

.info{
    display: flex;
    align-items: center;
    gap: 30px;
}

.media-icon{
    font-size: 25px;
    color: var(--primary);
}

.info-spans{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.info-spans .one{
    color: var(--primary);
    font-size: 16px;
}

.info-spans .two{
    font-size: 14px;
    transform: translateY(-2px);
    color: var(--text);
}

.right{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
    height: 100%;
    padding-right: 50px;
}

.right h2{
    font-size: 35px;
    color: var(--primary);
}

.right .two-inputs{
    display: flex;
    gap: 20px;
}

.right .two-inputs input{
    height: 44px;
    border: 2px solid var(--primary-dark);
    background: var(--bg);
    border-radius: 6px;
    color: var(--primary);
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
}

.right input{
    height: 44px;
    border: 2px solid var(--primary-dark);
    background: var(--bg);
    border-radius: 6px;
    color: var(--primary);
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
}

.right textarea{
    height: 130px;
    border: 2px solid var(--primary-dark);
    background: none;
    border-radius: 6px;
    color: var(--primary);
    width: 100%;
    padding: 10px 10px;
    font-size: 17px;
    resize: none;
}

/* Footer section */
.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 60px;
    background: var(--primary);
}

.footer p{
    font-size: 18px;
    color: #fff;
    text-align: center;
}
/* Responsive  section*/

@media screen  and (max-width: 1146px){
    .service-box{
        width: 320px;
        height: 220px;
    } 
    .service-icon{
        font-size: 40px;
    }
    .service-heading{
        font-size: 22px;
    }
    .service-para{
        font-size: 14px;
        padding: 5px 15px;
    }

    /* contact  */
    
}

@media screen  and (max-width: 948px){
    /* navbar  */
    .left{
        padding-left: 100px; 
    }
    .left h1{
        font-size: 35px;
    }
    .left p{
        padding-right: 120px;
        font-size: 17px;
    }
}

@media screen  and (max-width: 810px){
    /* Re-usable */
    .btn{
        padding: 8px 12px;
    }
    .btn2{
        font-size: 16px;
        padding: 8px 12px;
    }
    /* navbar  */
    .logo h3{
        font-size: 24px;
    }
    .logo_icon{
        font-size: 12px;
    }
    .logo p{
        font-size: 9px;
    }
    .navlist .nav-links{
        font-size: 15px;
    }

    /* Home  */
    .left{
    padding-left: 90px;
    }
    .left h1{
        font-size: 30px;
        padding-right: 20px;
    }
    .left p{
        padding-right: 80px;
        font-size: 16px;
    }

    /* contact  */
    .contact-box{
        flex-direction: column;
        padding-top: 20px;
        gap: 50px;
    }
    .left{
        width: 100%;
        height: max-content;
    }
    .right{
        width: 100%;
        height: max-content;
        padding: 0 60px;
    }
}

@media screen  and (max-width: 670px){
    /* Re-usable */
    .btn{
        font-size: 14px;
        padding: 6px 10px;
    }
    .btn2{
        font-size: 14px;
        padding: 6px 10px;
    }
    .hamburger_icon{
        display: block;
    }
    /* navbar  */
    .logo h3{
        font-size: 21px;
    }
    .logo_icon{
        font-size: 10px;
    }
    .logo p{
        font-size: 8px;
    }
    .navlist .nav-links{
        position: absolute;
        top: 60px;
        right: -150px;
        flex-direction: column;
        width: 140px;
        padding: 20px 0;
        background: white;
        gap: 15px;
        font-size: 14px;
        border-radius: 6px;
        background: var(--primary-light);
        transition: var(--trsn2);
    }
    .navlist .mobile-nav{
        right: 10px;
    }

    /* Home  */
    .left{
    padding-left: 60px;
    gap: 15px;
    }
    .left h1{
        font-size: 24px;
        padding-right: 20px;
    }
    .left p{
        padding-right: 60px;
        font-size: 14px;
    }
}

@media screen  and (max-width: 610px){
    /* Re-usable */
    .btn2{
        font-size: 16px;
        padding: 8px 12px;
    }

    /* navbar  */
    .container{
        flex-direction: column;
        gap: 20px;
    }
    .left{
        width: 100%;
    }
    .left h1{
        font-size: 35px;
        padding-right: 20px;
    }
    .left p{
        padding-right: 60px;
        font-size: 16px;
    }
    .Right{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    /* design  */
    .design-box{
        width: 350px;
        height: 165px;
    }
}

@media screen  and (max-width: 384px){
    /* Re-usable */
    .btn2{
        font-size: 14px;
        padding: 6px 10px;
    }

    /* navbar  */
    .left{
        width: 100%;
        padding-left: 40px;
    }
    .left h1{
        font-size: 30px;
        padding-right: 20px;
    }
    .left p{
        padding-right: 20px;
        font-size: 14px;
    }
    .Right{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    /* design  */
    .design-box{
        width: 250px;
        height: 118px;
    }

    /* contact  */
    .right{
        width: 100%;
        height: max-content;
        padding: 0 20px;
    }
}